import { CustomerAddress } from "./customer-address";

export class Customer {
    id!: number;
    contactName?: string;
    companyName?: string;
    phone?: string;
    email?: string;
    fax?: string;
    optIn!: boolean;
    mainAddressId!: number;
    paymentMethod?: string;
    mainAddress!: CustomerAddress

    get displayName() {
        return this.companyName ?? this.contactName;
    }

    public constructor(init?: Partial<Customer>) {
        Object.assign(this, { ...init, mainAddress: new CustomerAddress(init?.mainAddress)});
    }
}
