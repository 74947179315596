import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, computed, inject, input } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { Part } from '../core/models/part';
import { InvoiceService } from '../core/invoice.service';
import { PictureService } from '../core/picture.service';
import { ApplicationInfoService } from '../core/application-info.service';
import { environment } from '../../environments/environment';
import { UtilityService } from '../core/utility.service';

@Component({
    selector: 'app-part-item',
    standalone: true,
    imports: [CommonModule, RouterLink],
    template: `
        <div class="product-item">
            <div class="ribbon-wrapper">
                <div class="picture">
                    @if (!isWebComponent) {
                    <a [routerLink]="detailUrl()" title="Show details for {{ part().title }}" tabindex="-1">
                        <ng-template [ngTemplateOutlet]="partPicture"></ng-template>
                    </a>
                    } @else {
                    <a [href]="detailHref()" title="Show details for {{ part().title }}" tabindex="-1">
                        <ng-template [ngTemplateOutlet]="partPicture"></ng-template>
                    </a>
                    }
                </div>
                @if (discountPercent() !== 0) {
                <div class="ribbon-position top-right" tabindex="0">
                    <div class="product-ribbon">
                        <img id="ribbon-picture-1-37-107" src="/assets/template/0000510.png" />
                        <label class="ribbon-image-text" style="font-size: 18px; color: #fff; right: 5px; top: 5px; text-align: right;">
                            -{{ discountPercent() }}%
                        </label>
                    </div>
                </div>
                }
            </div>
            <div class="details">
                <h2 class="product-title">
                    @if (!isWebComponent) {
                    <a [routerLink]="[detailUrl()]" tabindex="-1">{{ part().title }}</a>
                    } @else {
                    <a [href]="detailHref()" tabindex="-1">{{ part().title }}</a>
                    }
                </h2>
                <div class="prices">
                    @if (part().promotionPrice !== part().price) {
                    <span class="price old-price">{{ part().price | currency }}</span>
                    }
                    <span class="price actual-price">{{ part().promotionPrice | currency }}</span>
                    @if (part().coreCharge > 0) {
                    <span>(core charge: {{ part().coreCharge | currency }})</span>
                    }
                </div>
                <div class="add-info" style="">
                    <div class="description">
                        @if (!isWebComponent) {
                        <a [routerLink]="[detailUrl()]" title="Show details for {{ part().title }}" tabindex="-1">
                            <ng-template [ngTemplateOutlet]="partDescription"></ng-template>
                        </a>
                        } @else {
                        <a [href]="detailHref()" title="Show details for {{ part().title }}" tabindex="-1">
                            <ng-template [ngTemplateOutlet]="partDescription"></ng-template>
                        </a>
                        }
                    </div>
                    <div class="buttons">
                        <div class="ajax-cart-button-wrapper qty-enabled">
                            <button
                                type="button"
                                class="button-2 product-box-add-to-cart-button"
                                title="{{ detailHref() }}"
                                (click)="addToCart()"
                                tabindex="-1"
                            >
                                Add to cart
                            </button>
                        </div>
                    </div>
                    @if (!isWebComponent) {
                    <a class="dummy-link" [routerLink]="[detailUrl()]" title="Show details for {{ part().title }}" tabindex="-1"></a>
                    } @else {
                    <a class="dummy-link" [href]="detailHref()" title="Show details for {{ part().title }}" tabindex="-1"></a>
                    }
                </div>
            </div>
        </div>

        <ng-template #partDescription>
            <div><strong>Condition:</strong> {{ part().condition }}</div>
            <div class="item-description">{{ part().description }}</div>
            @if (part().promotions) {
            <div class="item-description">
                @for(promotion of part().promotions; track promotion) {
                <div class="promotion-text">{{ promotion }}</div>
                }
            </div>
            }
        </ng-template>

        <ng-template #partPicture>
            <img src="{{ pictureUrl() }}" alt="Picture of {{ part().title }}" title="Show details for {{ part().title }}" />
        </ng-template>
    `,
    styles: `
        .item-description {
            margin-top: 15px;
        }
    `
})
export class PartItemComponent {
    appInfo = inject(ApplicationInfoService);
    invoiceService = inject(InvoiceService);
    pictureService = inject(PictureService);
    router = inject(Router);

    isWebComponent = environment.isWebComponent;

    part = input.required<Part>();

    detailUrl = computed(() => '/products/' + this.part().id);
    detailHref = computed(() => this.appInfo.addUtm(`${this.appInfo.shopUrl()}/products/${this.part().id}`));
    pictureUrl = computed(() => this.pictureService.getImageUrl('part', this.part().photoFileName, 400, 400, 'max'));
    discountPercent = computed(() => {
        const percent = 100 - (this.part().promotionPrice / this.part().price) * 100;
        return UtilityService.round(percent, 0);
    });

    addToCart() {
        if (environment.isWebComponent) {
            location.href = this.appInfo.addUtm(`${this.appInfo.shopUrl()}/checkout/cart?partId=${this.part().id}`);
        } else {
            this.invoiceService.addToCart(this.part());
        }
    }
}
