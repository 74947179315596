import { CommonModule } from '@angular/common';
import { Component, computed, inject, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { DxTextBoxComponent, DxTextBoxModule } from 'devextreme-angular';
import { ApplicationInfoService } from '../core/application-info.service';
import { StoreService } from '../core/store.service';

@Component({
    selector: 'app-header',
    standalone: true,
    imports: [CommonModule, RouterLink, RouterLinkActive, FormsModule, DxTextBoxModule],
    template: `
        <div class="header-items">
            <div class="overlayOffCanvas"></div>
            <div class="responsive-nav-wrapper-parent">
                <div class="responsive-nav-wrapper">
                    <div class="responsive-nav-inner">
                        <div class="personal-button" id="header-links-opener"><span>Personal menu</span></div>
                        <div class="menu-title"><span>Menu</span></div>
                        <div class="search-wrap"><span>Search</span></div>
                    </div>
                </div>
            </div>
            <div class="subheader-wrapper">
                <div class="logo-wrapper">
                    <div class="header-logo">
                        <a [href]="homeHref()" class="logo">
                            <img alt="{{ appInfo.info.companyName }} Logo" title="{{ appInfo.info.companyName }}" src="{{ logoUrl }}"
                        /></a>
                    </div>
                </div>
                <div class="headerMenuParent">
                    <div class="header-menu">
                        <div class="close-menu"><span>Close</span></div>
                        <ul class="mega-menu">
                            @if(!showSearch){
                            <li>
                                <a [href]="homeHref()" routerLinkActive="active" title="Home"><span> Home</span></a>
                            </li>
                            <li>
                                <a [href]="aboutHref()" routerLinkActive="active" title="About"><span> About</span></a>
                            </li>
                            <li>
                                <a routerLink="/products" routerLinkActive="active" title="Products"><span> Products</span></a>
                            </li>
                            @if (appInfo.info.companyMode === 'Kamper') {
                            <li>
                                <a [href]="blogHref()" routerLinkActive="active" title="Blog"><span> Blog</span></a>
                            </li>
                            }
                            <li>
                                <a [href]="contactHref()" routerLinkActive="active" title="Contact us"><span> Contact us</span></a>
                            </li>
                            <li></li>
                            } @else {
                            <li class="search">
                                <dx-text-box
                                    #searchComponent
                                    placeholder="Search for products..."
                                    (onEnterKey)="search()"
                                    [(value)]="searchValue"
                                >
                                </dx-text-box>
                                <a (click)="toggleShowSearch()">x</a>
                            </li>
                            }
                            <li class="cart">
                                <a title="Search" (click)="toggleShowSearch()" class="ico-search">
                                    <i class="fas fa-light fa-search fa-flip-horizontal"></i>
                                </a>
                                <a routerLink="/checkout/cart" class="ico-cart">
                                    <i class="fas fa-solid fa-shopping-cart"></i>
                                </a>
                            </li>
                        </ul>
                        <div class="menu-title"><span>Menu</span></div>
                        <ul class="mega-menu-responsive">
                            <li>
                                <a [href]="homeHref()" routerLinkActive="active" title="Home"><span> Home</span></a>
                            </li>
                            <li>
                                <a routerLink="/products" routerLinkActive="active" title="Products"><span> Products</span></a>
                            </li>
                            <li>
                                <a [href]="contactHref()" routerLinkActive="active" title="Contact us"><span> Contact us</span></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    `,
    styles: `
        .header-items {
            display: block;
            justify-content: space-between;
            align-items: center;

        .logo-wrapper {
            width: auto;
        }

        .header-logo {
            height: 102px;
            margin-right: 50px;
            flex-grow: 1;

            .logo {
                max-width: unset;
            }

            img {
                max-width: 100%;
                height: 102px;
            }
        }

        .header-selectors-wrapper {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }

        .header-menu {
            display: flex;
            gap: 10px;
            align-items: center;

            .mega-menu {
                display: flex;
                gap: 10px;
                align-items: center;
                width: 100%;
            }
        }

        .header-links {
            align-self: flex-end;
            a {
                font-size: 13px;
            }
        }

        .cart-qty {
            font-size: 11px;
            right: -18px;
        }

        .search {
            width: 70%;
            display: flex;
            align-items: center;
            gap: 10px;

            dx-text-box {
                flex-grow: 1;
            }
        }

        .cart {
            margin-left: auto;
            margin-bottom: 0;
        }

        .ico-cart {
            display: inline-block;
            color: #fff;
            font-size: 25px;
            text-align: right;
        }

        .ico-search {
            display: inline-block;
            color: #fff;
            font-size: 20px;
            margin-right: 25px;
        }
        }
    `
})
export class HeaderComponent {
    router = inject(Router);
    route = inject(ActivatedRoute);
    storeService = inject(StoreService);
    appInfo = inject(ApplicationInfoService);

    homeHref = computed(() => this.appInfo.addUtm(this.wordpressUrl));
    aboutHref = computed(() => this.appInfo.addUtm(`${this.wordpressUrl}/about`));
    blogHref = computed(() => this.appInfo.addUtm(`${this.wordpressUrl}/blog`));
    contactHref = computed(() => this.appInfo.addUtm(`${this.wordpressUrl}/contact-us`));

    wordpressUrl = this.appInfo.info.wordPressUrl;
    logoUrl =
        this.appInfo.info.companyMode === 'PPM' ? '/assets/images/ppm-logo-dark-200.png' : '/assets/images/logo-transparent-white.png';
    showSearch: boolean = false;
    searchValue: string = '';

    @ViewChild('searchComponent') searchComponent?: DxTextBoxComponent;

    search() {
        const searchValue = this.searchValue;
        this.searchValue = '';
        this.showSearch = false;

        this.router.navigate(['/search'], {
            relativeTo: this.route,
            queryParams: { search: searchValue ? searchValue : undefined }
        });
    }

    toggleShowSearch() {
        this.showSearch = !this.showSearch;
        if (this.showSearch) {
            setTimeout(() => {
                this.searchComponent!.instance.focus();
            });
        }
    }
}
