import { PartsService } from '../parts.service';
import { UtilityService } from '../utility.service';
import { Part } from './part';
import { Promotion } from './promotion';

export class InvoiceLine {
    id!: number;
    lineNumber!: number;
    partId!: number;
    partNumber?: string;
    quantity!: number;
    price!: number;
    description?: string;
    part!: Part;
    isCoreCharge!: boolean;
    promotions!: string;

    newQuantity!: number;
    promotionDtos!: Promotion[];
    hasFreeShipping!: boolean;

    get title() {
        if (this.isCoreCharge) return 'Core charge';
        return this.part.title;
    }

    get linePrice() {
        return this.part.price;
    }

    setPromotions(promotions: Promotion[]) {
        this.promotionDtos = promotions;
        const promotion = this.promotionDtos.map((promotion) => promotion.text).join('\n');
        if (this.promotions !== promotion) this.promotions = promotion;
        this.hasFreeShipping = this.promotionDtos.some((promotion) => promotion.type === Promotion.Types.FreeShipping);
    }

    updatePrice() {
        if (this.isCoreCharge) {
            this.price = this.part.coreCharge;
            return;
        }

        const price = PartsService.getPromotionPrice(this.linePrice, this.promotionDtos);
        if (this.price !== price) this.price = price;
    }

    public constructor(init?: Partial<InvoiceLine>) {
        Object.assign(this, {
            ...init,
            part: new Part(init?.part),
            newQuantity: init?.quantity,
            promotionDtos: init?.promotionDtos ?? []
        });
        this.updatePrice();
    }
}
