export class Promotion {
    static Types = {
        Discount: 1,
        FreeShipping: 2,
        FreeItem: 3
    };

    id!: number;
    type!: number;
    partTypeId?: number;
    condition?: string;
    startDate!: Date;
    endDate!: Date;
    text!: string;
    discount!: number;
}
