import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject, signal } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';
import { Meta } from '@angular/platform-browser';

type AppInfo = {
    companyMode?: string;
    companyName?: string;
    containerName?: string;
    version?: string;
    countryShippingMethods?: { code: 'US' | 'CA'; shippingMethods: { value: string; name: string }[] }[];
    wordPressUrl?: string;
    featureFlags: any;
};

@Injectable({
    providedIn: 'root'
})
export class ApplicationInfoService {
    titleService = inject(Title);
    metaService = inject(Meta);
    http = inject(HttpClient);

    info: AppInfo = { featureFlags: {} };
    shopUrl = signal(environment.serviceUrl);

    async init() {
        // await this.getApplicationInfo();
    }

    setTitle(title: string) {
        const siteTitle = this.info.companyMode === 'PPM' ? 'Power Play Manifolds' : 'NA Diesel';

        const pageTitle = title ? `${title} | ${siteTitle}` : siteTitle;
        this.titleService.setTitle(pageTitle);
        this.metaService.removeTag('name=description');
        // this.metaService.removeTag('name=keywords');
    }

    setDescription(description: string) {
        this.metaService.addTag({ name: 'description', content: description });
    }

    setMeta(key: string, value: string) {
        this.metaService.addTag({ name: key, content: value });
    }

    appInfoPromise: Promise<AppInfo> | null = null;
    async getApplicationInfo() {
        if (this.appInfoPromise) {
            return await this.appInfoPromise;
        }
        console.log('getApplicationInfo', this.shopUrl());
        this.appInfoPromise = firstValueFrom(this.http.get<AppInfo>(`${this.shopUrl()}/api/utility/application-info`));
        const data = await this.appInfoPromise;
        data.companyName = data.companyMode === 'PPM' ? 'Power Play Manifolds' : 'North American Diesel Parts';
        this.info = data;
        return data;
    }

    getShippingMethodsByCountry(code: 'US' | 'CA') {
        return this.info.countryShippingMethods!.find((country) => country.code === code)?.shippingMethods ?? [];
    }

    getUtm(url?: string) {
        if (!url) return [];

        const urlParts = url.split('?');
        if (!urlParts[1]) return [];

        return urlParts[1]
            .split('&')
            .filter((s) => s.toLowerCase().startsWith('utm_'))
            .map((s) => {
                return { key: s.substring(4).split('=')[0], value: s.split('=')[1] };
            });
    }

    addUtm(url?: string) {
        if (!url) return url ?? '';

        const urlParts = url.split('?');
        const params = new HttpParams({ fromString: urlParts[1] })
            .set('utm_source', environment.isWebComponent ? 'wordpress' : 'shop')
            .set('utm_medium', 'website');
        return `${urlParts[0]}?${params.toString()}`;
    }
}
