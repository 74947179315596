import { APP_INITIALIZER, ApplicationConfig, ErrorHandler } from '@angular/core';
import { provideRouter, withInMemoryScrolling, withRouterConfig, withViewTransitions } from '@angular/router';

import { routes } from './app.routes';
import { provideHttpClient } from '@angular/common/http';
import { RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { environment } from '../environments/environment';
import { ToastErrorHandler } from './core/toast-error-handler.service';
import { ApplicationInfoService } from './core/application-info.service';

export function initializeApp(appInfo: ApplicationInfoService) {
    return (): Promise<any> => appInfo.getApplicationInfo();
}

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(routes, withViewTransitions(), withInMemoryScrolling({ scrollPositionRestoration: 'enabled' })),
        provideHttpClient(),
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            multi: true,
            deps: [ApplicationInfoService]
        },
        {
            provide: RECAPTCHA_SETTINGS,
            useValue: {
                siteKey: environment.recaptcha.siteKey
            } as RecaptchaSettings
        },
        // { provide: 'googleTagManagerId', useValue: 'GTM-TZWVFWTM' },
        // { provide: 'googleTagManagerId', useValue: 'G-X5PKQ07QVF' },
        { provide: ErrorHandler, useClass: ToastErrorHandler }
    ]
};
