import { Component, computed, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApplicationInfoService } from '../core/application-info.service';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';

@Component({
    selector: 'app-footer',
    standalone: true,
    imports: [CommonModule, RouterLink],
    template: `
        <div class="footer">
            <div class="footer-upper">
                <div class="center">
                    <div class="footer-block contacts">
                        <div class="title"><strong>Contacts</strong></div>
                        <ul>
                            <li class="phone">(Toll Free) 855-470-0404</li>
                            <li class="phone">(Local) 616-997-2416</li>
                            <li class="fax">(Fax) 855-470-0404</li>
                            <li class="email">info&#64;nadieselparts.com</li>
                        </ul>
                    </div>
                    <div class="footer-block recently-viewed">
                        <div class="title"><strong>Address</strong></div>
                        <div class="footer-group">
                            <div>
                                <strong>{{ appInfo.info.companyName }}</strong>
                            </div>
                            <div>411 64th Ave</div>
                            <div>Coopersville, MI 49404</div>
                        </div>
                    </div>
                    <div class="footer-block contacts">
                        <div class="title"><strong>Hours</strong></div>
                        <div class="footer-group">
                            <div>Monday - Friday: 7:30-5:00</div>
                            <div>Saturday: Closed</div>
                            <div>Sunday: Closed</div>
                        </div>
                        <div class="footer-group" style="padding-top: 20px">
                            <div><a [href]="warrantyInfoHref()" class="link">Request Warranty</a></div>
                            <div><a [href]="warrantyPolicyHref()" class="link">Warranty Policy</a></div>
                        </div>
                    </div>
                    <div class="footer-block">
                        <div class="title"><strong></strong></div>
                        <img src="{{ logoUrl }}" alt="Logo" />
                    </div>
                </div>
            </div>
            <div class="footer-middle">
                <div class="center">
                    <ul class="social-sharing">
                        <li>
                            <a
                                target="_blank"
                                class="facebook"
                                href="https://www.facebook.com/NorthAmericanDieselParts/"
                                rel="noopener noreferrer"
                                aria-label="Facebook"
                            >
                                <i class="fab fa-facebook-f"></i>
                            </a>
                        </li>
                        <li>
                            <a
                                target="_blank"
                                class="linkedin"
                                href="https://www.linkedin.com/in/tim-kamps-5292546a/"
                                rel="noopener noreferrer"
                                aria-label="LinkedIn"
                            >
                                <i class="fab fa-regular fa-linkedin"></i>
                            </a>
                        </li>
                    </ul>
                    <ul class="newsletter">
                        <div class="button-container">
                            <button class="default-button" (click)="subscribeToNewsletter()">SUBSCRIBE TO NEWSLETTER</button>
                        </div>
                    </ul>
                    <!-- <div class="newsletter">
                        <div class="title"><strong>Newsletter</strong></div>
                        <div class="newsletter-subscribe" id="newsletter-subscribe-block">
                            <div class="newsletter-email">
                                <input
                                    id="newsletter-email"
                                    class="newsletter-subscribe-text"
                                    placeholder="Enter your email here..."
                                    aria-label="Sign up for our newsletter"
                                    type="email"
                                    name="NewsletterEmail"
                                />
                                <button type="button" id="newsletter-subscribe-button" class="button-1 newsletter-subscribe-button">
                                    Subscribe
                                </button>
                            </div>
                            <div class="newsletter-validation">
                                <span id="subscribe-loading-progress" style="display: none" class="please-wait">Wait...</span>
                                <span class="field-validation-valid" data-valmsg-for="NewsletterEmail" data-valmsg-replace="true"></span>
                            </div>
                        </div>
                        <div class="newsletter-result" id="newsletter-result-block"></div>
                    </div> -->
                    <ul class="accepted-payment-methods">
                        <img src="/assets/images/payment-methods.jpg" alt="Accepted payment methods" />
                        <!-- <li class="method1"></li>
                        <li class="method2"></li>
                        <li class="method3"></li> -->
                    </ul>
                </div>
            </div>
            <div class="footer-lower">
                <div class="center">
                    <div class="footer-disclaimer">
                        <span title="{{ version }}">Copyright © {{ year }} {{ appInfo.info.companyName }}. All rights reserved.</span>
                    </div>
                    <div class="footer-designed-by">
                        <!-- Created by <a href="https://www.pectechnologies.com/" target="_blank">PEC Technologies</a> -->
                    </div>
                    <div class="footer-store-theme"></div>
                </div>
            </div>
        </div>
    `,
    styles: `
        .footer-group div {
            padding: 5px 10px;
        }

        .newsletter {
            width: 50%;
        }

        .button-container {
            display: flex;
            justify-content: center;
            gap: 10px;
        }

        a.facebook, a.linkedin {
            background-image: none;
            color: #000;
            font-size: 24px;
        }
    `
})
export class FooterComponent {
    appInfo = inject(ApplicationInfoService);
    router = inject(Router);
    route = inject(ActivatedRoute);

    warrantyInfoHref = computed(() => this.appInfo.addUtm(`${this.wordpressUrl}/warranty-information`));
    warrantyPolicyHref = computed(() => this.appInfo.addUtm(`${this.wordpressUrl}/warranty-policy`));
    logoUrl = this.appInfo.info.companyMode === 'PPM' ? '/assets/images/ppm-logo.jpg' : '/assets/images/logo.png';

    year = new Date().getFullYear();
    version = this.appInfo.info.version!;
    wordpressUrl = this.appInfo.info.wordPressUrl!;

    subscribeToNewsletter() {
        location.href = this.appInfo.addUtm(`${this.wordpressUrl}/#subscribe-popup`);
    }
}
