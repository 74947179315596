import { ChangeDetectionStrategy, Component, OnInit, inject, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { ApplicationInfoService } from './core/application-info.service';
import { PartsService } from './core/parts.service';
import { StoreService } from './core/store.service';
import { InvoiceService } from './core/invoice.service';
import { UtilityService } from './core/utility.service';

@Component({
    selector: 'app-root',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.Default,
    imports: [CommonModule, RouterOutlet, HttpClientModule],
    template: ` <router-outlet /> `,
    styles: ``
})
export class AppComponent implements OnInit {
    // appInfo = inject(ApplicationInfoService);
    storeService = inject(StoreService);
    partsService = inject(PartsService);
    invoiceService = inject(InvoiceService);
    utilityService = inject(UtilityService);

    isHome = signal(false);

    async ngOnInit() {
        this.storeService.setLoading(true);
        await Promise.all([
            this.utilityService.getWebPhotos(),
            this.partsService.getPartTypes(),
            this.partsService.getMakes(),
            this.partsService.getMakeContents(),
            this.partsService.getPartTypeModelMaps(),
            this.partsService.getPromotions(),
        ]);
        // get invoice after getting all the other data so that the invoice can be updated correctly
        await this.invoiceService.getFromStorage();
        this.storeService.setLoading(false);
    }
}
