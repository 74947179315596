import { Injectable, inject } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { Invoice } from './models/invoice';
import { HttpClient } from '@angular/common/http';
import { StoreService } from './store.service';
import { Part } from './models/part';
import { InvoiceLine } from './models/invoice-line';
import { environment } from '../../environments/environment';
import { DialogService } from './dialog.service';
import { SessionInformationService } from './session-information-service';
import { Response } from './models/response';
import { AddedToCartDialogComponent } from '../shared/added-to-cart-dialog.component';
import { ApplicationInfoService } from './application-info.service';
import { PartsService } from '../core/parts.service';
import { ShippingEstimate } from './models/shipping-estimate';
import { UtilityService } from './utility.service';
import { AnalyticsService } from './analytics.service';
import { Promotion } from './models/promotion';

export type ShippingRequest = {
    name: string;
    company: string;
    address1: string;
    city: string;
    stateCode: string;
    zipCode: string;
    countryCode: string;
    shipMethod: string;
    phone: string;
    parts: {
        id: number;
        weight: number;
        length: number;
        width: number;
        height: number;
        quantity: number;
        price: number;
    }[];
};

@Injectable({
    providedIn: 'root'
})
export class InvoiceService {
    appInfo = inject(ApplicationInfoService);
    storeService = inject(StoreService);
    http = inject(HttpClient);
    dialogService = inject(DialogService);
    sessionInfoService = inject(SessionInformationService);
    partsService = inject(PartsService);
    analyticsService = inject(AnalyticsService);

    async getInvoice(invoiceNumber: number) {
        const data = await firstValueFrom(
            this.http.get<Invoice>(`${this.appInfo.shopUrl()}/api/invoices/detail?invoiceNumber=${invoiceNumber}`)
        );
        return new Invoice(data);
    }

    async cardpointeWebhook() {
        const invoice = this.storeService.invoice();
        await firstValueFrom(
            this.http.post(`${this.appInfo.shopUrl()}/api/invoices/cardpointe-webhook`, {
                invoice: invoice.invoiceNumber,
                merchantId: '496591856885',
                total: invoice.total,
                paymentType: 'CC'
            })
        );
    }

    async addToCart(part: Part, quantity: number = 1, showAlertOnly: boolean = false) {
        if (this.storeService.invoice().invoiceLines.some((l) => l.partId === part.id)) {
            await this.dialogService.alert('This part is already in your cart');
            return;
        }

        if (!part.serialized) {
            if (part.quantity == 0) {
                await this.dialogService.alert(`Sorry, ${part.title} is not in stock.`);
                return;
            } else if (quantity > part.quantity) {
                await this.dialogService.alert(`Sorry, only ${part.quantity} of ${part.title} are in stock. Please decrease the quantity.`);
                return;
            }
        }

        await this.addInvoiceLine(
            new InvoiceLine({
                partId: part.id,
                part: part,
                partNumber: part.partNumber,
                quantity: quantity,
                description: part.description
            })
        );

        if (part.coreCharge > 0) {
            await this.addInvoiceLine(
                new InvoiceLine({
                    partId: part.id,
                    part: part,
                    partNumber: part.partNumber,
                    quantity: 1,
                    description: 'Core charge',
                    isCoreCharge: true
                })
            );
        }

        this.analyticsService.gtag('add_to_cart', {
            item_id: part.id,
            item_name: part.title,
            value: part.price,
            quantity: quantity
        });

        if (showAlertOnly) await this.dialogService.alert('This part was added to your cart');
        else await this.dialogService.show(AddedToCartDialogComponent);
    }

    async updateQuantity(invoice: Invoice) {
        const lines = invoice.invoiceLines.slice();
        lines.forEach((line) => {
            if (line.newQuantity === 0) invoice.invoiceLines.splice(invoice.invoiceLines.indexOf(line), 1);
            else if (line.newQuantity !== line.quantity) line.quantity = line.newQuantity;
        });
        await this.setInvoice(invoice);
    }

    async saveInvoice(invoice: Invoice) {
        const response = await firstValueFrom(
            this.http.post<{ success: boolean; message: string; value: { id: number; invoiceNumber: number } }>(
                `${this.appInfo.shopUrl()}/api/invoices/save`,
                invoice
            )
        );
        invoice.invoiceNumber = response.value.invoiceNumber;
        invoice.id = response.value.id;
        await this.setInvoice(invoice);
        return response.success;
    }

    async getFromStorage() {
        const invoice = this.sessionInfoService.getItem('invoice');
        if (invoice) await this.setInvoice(new Invoice(invoice));
    }

    async checkIfPaid() {
        if (this.storeService.mode() !== 'payment') return;
        const invoiceNumber = this.storeService.invoice().invoiceNumber;
        if (!invoiceNumber) return;

        const status = await this.getStatus(invoiceNumber);
        if (status === Invoice.StatusOptions.WebComplete) await this.clearInvoice();
        this.storeService.setMode('shop');
    }

    async getStatus(invoiceNumber: number) {
        const response = await firstValueFrom(
            this.http.get<Response<number>>(`${this.appInfo.shopUrl()}/api/invoices/status?invoiceNumber=${invoiceNumber}`)
        );
        return response.value;
    }

    async getShippingRate(invoice: Invoice) {
        const request = this.getShippingRequest(invoice);
        const storeRequest = this.storeService.shippingRateRequest();
        if (this.useStore(storeRequest, request)) return { success: true, value: this.storeService.shippingRate() } as Response<number>;

        let response;
        try {
            this.storeService.setShippingRateRequest(request);
            response = await firstValueFrom(
                this.http.post<Response<number>>(`${this.appInfo.shopUrl()}/api/invoices/shipping-rate`, request)
            );
        } catch (error: any) {
            response = {
                success: false,
                message: `An error occurred while getting ship rate. ${error.message}`
            } as Response<number>;
        }
        if (!response.success) this.storeService.setShippingRateRequest(null);
        this.storeService.setShippingRate(response.value);
        return response;
    }

    async getShippingEstimates(invoice: Invoice) {
        const request = this.getShippingRequest(invoice);
        const storeRequest = this.storeService.shippingEstimatesRequest();
        if (this.useStore(storeRequest, request))
            return { success: true, value: this.storeService.shippingEstimates() } as Response<ShippingEstimate[]>;

        let response;
        try {
            this.storeService.setShippingEstimatesRequest(request);
            response = await firstValueFrom(
                this.http.post<Response<ShippingEstimate[]>>(`${this.appInfo.shopUrl()}/api/invoices/shipping-rate-estimates`, request)
            );
        } catch (error: any) {
            response = {
                success: false,
                message: `An error occurred while getting ship rate estimates. ${error.message}`
            } as Response<ShippingEstimate[]>;
        }
        if (!response.success) this.storeService.setShippingEstimatesRequest(null);
        this.storeService.setShippingEstimates(response.value);
        return response;
    }

    private useStore(storeRequest: ShippingRequest | null, newRequest: ShippingRequest) {
        if (!storeRequest) return false;

        const isAddressUnchanged =
            storeRequest.address1 === newRequest.address1 &&
            storeRequest.city === newRequest.city &&
            storeRequest.stateCode === newRequest.stateCode &&
            storeRequest.zipCode === newRequest.zipCode &&
            storeRequest.countryCode === newRequest.countryCode;
        const isShipMethodUnchanged =
            storeRequest.shipMethod === newRequest.shipMethod ||
            (storeRequest.shipMethod.startsWith('Freight') && newRequest.shipMethod.startsWith('Freight'));
        const hasSameNumberOfParts = storeRequest.parts.length === newRequest.parts.length;
        const arePartsUnchanged = storeRequest.parts.every((part) => {
            const newPart = newRequest.parts.find((p) => p.id === part.id);
            if (!newPart) return false;
            return (
                newPart.height === part.height &&
                newPart.length === part.length &&
                newPart.width === part.width &&
                newPart.weight === part.weight &&
                newPart.quantity === part.quantity
            );
        });
        return isAddressUnchanged && isShipMethodUnchanged && hasSameNumberOfParts && arePartsUnchanged;
    }

    getShippingRequest(invoice: Invoice) {
        return {
            name: invoice.customer.contactName,
            company: invoice.customer.companyName,
            address1: invoice.shipAddress.address1,
            city: invoice.shipAddress.city,
            stateCode: invoice.shipAddress.state,
            zipCode: invoice.shipAddress.zipCode,
            countryCode: this.getCountryCode(invoice),
            shipMethod: invoice.shipMethod,
            phone: invoice.customer.phone,
            parts: invoice.invoiceLines
                .filter((line) => !line.isCoreCharge)
                .filter((line) => !line.hasFreeShipping)
                .map((line) => {
                    return {
                        id: line.part.id,
                        weight: line.part.weight,
                        length: line.part.length,
                        width: line.part.width,
                        height: line.part.height,
                        quantity: line.quantity,
                        price: line.price
                    };
                })
        } as ShippingRequest;
    }

    async refreshInvoice() {
        return await this.setInvoice(this.storeService.invoice());
    }

    async clearInvoice() {
        await this.setInvoice(new Invoice());
    }

    async addInvoiceLine(line: InvoiceLine) {
        const invoice = this.storeService.invoice();
        invoice.invoiceLines.push(line);
        this.updateLineNumbers(invoice.invoiceLines);
        await this.setInvoice(invoice);
    }

    async removeInvoiceLine(line: InvoiceLine) {
        const invoice = this.storeService.invoice();
        this.removeLine(invoice, line);

        if (line.part.coreCharge > 0) {
            const coreLine = invoice.invoiceLines.find((l) => l.partId === line.partId && l.isCoreCharge);
            this.removeLine(invoice, coreLine);
        }

        this.updateLineNumbers(invoice.invoiceLines);
        await this.setInvoice(invoice);
    }

    private removeLine(invoice: Invoice, line?: InvoiceLine) {
        if (!line) return;
        const lineIndex = invoice.invoiceLines.indexOf(line);
        if (lineIndex === -1) return;
        invoice.invoiceLines.splice(lineIndex, 1);
    }

    setSourceFromParams(utm: { key: string; value: string }[]) {
        if (!utm || utm.length === 0) return;

        const invoice = this.storeService.invoice();
        invoice.source = utm.map((s) => `${s.key}: ${s.value}`).join(', ');
        this.setInvoice(invoice);
    }

    getSource() {
        const invoice = this.storeService.invoice();
        if (!invoice || !invoice.source) return [];

        return invoice.source
            .split(', ')
            .filter((s) => s && s.includes(': '))
            .map((s) => {
                return { key: s.split(': ')[0], value: s.split(': ')[1] };
            });
    }

    private async setInvoice(invoice: Invoice) {
        const errors = await this.updateTotals(invoice);
        this.storeService.setInvoice(invoice);
        this.sessionInfoService.setItem('invoice', this.storeService.invoice());
        return errors;
    }

    private async updateTotals(invoice: Invoice) {
        const promotions = this.storeService.promotions() ?? [];
        await this.updateLinePrice(invoice, promotions);
        const errors = await this.calculateShipping(invoice);
        const rate = this.getTaxRate(invoice);
        invoice.tax = UtilityService.round(invoice.subtotal * rate, 2);
        invoice.total = UtilityService.round(invoice.freight + invoice.tax + invoice.subtotal, 2);
        return errors;
    }

    private getTaxRate(invoice: Invoice) {
        const isFromMi = !invoice.shipAddress || !invoice.shipAddress.state || UtilityService.compare(invoice.shipAddress.state , 'MI');
        return isFromMi ? 0.06 : 0.0;
    }

    private updateLineNumbers(lines: InvoiceLine[]) {
        lines.forEach((line, index) => (line.lineNumber = index + 1));
    }

    async calculateShipping(invoice: Invoice) {
        if (!invoice || invoice.isShipMethodFreight) return;

        const error = this.validForGettingShippingRate(invoice);
        if (error) return error;

        const response = await this.getShippingRate(invoice);
        invoice.freight = response.success ? response.value! : 0;
        return response.message;
    }

    validForGettingShippingRate(invoice: Invoice) {
        if (!invoice.shipMethod) return 'Ship method required';
        if (!invoice.shipAddress) return 'Shipping address required';
        if (!invoice.shipAddress.address1) return 'Address 1 required';
        if (!invoice.shipAddress.city) return 'City required';
        if (!invoice.shipAddress.state) return 'State required';
        if (!invoice.shipAddress.zipCode) return 'Postal Code required';
        if (!invoice.shipAddress.country) return 'Country required';
        if (!invoice.invoiceLines || invoice.invoiceLines.length === 0) return 'At least one part is required';
        return null;
    }

    async hasValidQuantities(invoice: Invoice) {
        const partIds = invoice.invoiceLines.map((x) => x.partId);
        const parts = await this.partsService.getParts({ partIds: partIds });

        let errorMessage: string[] = [];
        invoice.invoiceLines.forEach((line) => {
            const part = parts.find((x) => x.id == line.partId);
            if (!part) {
                errorMessage.push(`Sorry, ${line.part.title} is no longer available.`);
            } else if (!part.serialized) {
                if (part.quantity == 0) {
                    errorMessage.push(`Sorry, ${part.title} is not in stock.`);
                } else if (line.quantity > part.quantity) {
                    errorMessage.push(`Sorry, only ${part.quantity} of ${part.title} are in stock. Please decrease the quantity.`);
                }
            }
        });

        if (errorMessage.length > 0) {
            this.dialogService.alert(errorMessage.join('<br/>'), true);
            return false;
        } else {
            return true;
        }
    }

    getCountryCode(invoice: Invoice) {
        if (invoice.shipAddress.country === 'United States') return 'US';
        else if (invoice.shipAddress.country === 'Canada') return 'CA';
        else if (invoice.shipAddress.country === 'Mexico') return 'MX';
        return null;
    }

    async updateLinePrice(invoice: Invoice, promotions: Promotion[]) {
        invoice.invoiceLines.forEach((line) => {
            if (!line.isCoreCharge) {
                const eligible = this.partsService.getEligiblePromotions(line.part.partTypeId, line.part.condition, promotions);
                line.setPromotions(eligible);
            }
            line.updatePrice();
        });
    }
}
