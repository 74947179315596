import { Injectable, inject } from '@angular/core';
import { Contact } from './models/contact';
import { firstValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { StoreService } from './store.service';
import { WebPhotos } from './store.service';
import { ApplicationInfoService } from './application-info.service';

@Injectable({
    providedIn: 'root',
})
export class UtilityService {
    appInfo = inject(ApplicationInfoService);
    http = inject(HttpClient);
    storeService = inject(StoreService);

    static round(value: number, decimalPlaces: number = 0) {
        // negative numbers are rounded towards zero, which is wrong. To fix, convert value to positive, round, then convert back to negative
        return (Math.sign(value) * Math.round(Number((Math.abs(value) * Math.pow(10, decimalPlaces)).toFixed(decimalPlaces)))) / Math.pow(10, decimalPlaces);
    }

    pickRandomItems<T>(items: T[], number: number) {
        const shuffled = items.slice().sort(() => 0.5 - Math.random());
        return shuffled.slice(0, number);
    }

    async submitContactForm(contact: Contact) {
        const invoiceNumber = await firstValueFrom(this.http.post<any>(`${this.appInfo.shopUrl()}/api/utility/submit-contact-form`, contact));
        return invoiceNumber;
    }

    async getWebPhotos() {
        const webPhotos = await firstValueFrom(this.http.get<WebPhotos>(`${this.appInfo.shopUrl()}/api/utility/web-photos`));
        this.storeService.setWebPhotos(webPhotos);
    }

    static toKebabCase(value: string | undefined) {
        return value?.toLowerCase().replaceAll(' ', '-').replaceAll('/', '-') ?? '';
    }

    static validateEmailAddress(email: string) {
        if (!email) return true;

        return /^(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?)$/g.test(
            email.trim()
        );
    }

    static compare(original: string, value: string) {
        if (!original || !value) return original == value;
        const result = original.localeCompare(value, undefined, { sensitivity: 'base' })
        return result === 0;
    }
}
