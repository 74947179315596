import { Injectable, WritableSignal, computed, signal } from '@angular/core';
import { Invoice } from './models/invoice';
import { Make } from './models/make';
import { PartType } from './models/part-type';
import { PartTypeModelMap } from './models/part-type-model-map';
import { ShippingEstimate } from './models/shipping-estimate';
import { ShippingRequest } from './invoice.service';
import { Promotion } from './models/promotion';
import { MakeContent } from './models/make-content';

type AppStore = {
    loading: WritableSignal<boolean>;
    partTypes: WritableSignal<PartType[]>;
    makes: WritableSignal<Make[]>;
    makeContents: WritableSignal<MakeContent[]>;
    invoice: WritableSignal<Invoice>;
    mode: WritableSignal<'shop' | 'payment'>;
    webPhotos: WritableSignal<WebPhotos>;
    partTypeModelMaps: WritableSignal<PartTypeModelMap[]>;
    shippingRate: WritableSignal<number | null>;
    shippingRateRequest: WritableSignal<ShippingRequest | null>;
    shippingEstimates: WritableSignal<ShippingEstimate[] | null>;
    shippingEstimatesRequest: WritableSignal<ShippingRequest | null>;
    promotions: WritableSignal<Promotion[]>;
};

type InvoiceSummary = {
    id: number;
    invoiceNumber: number;
    date: Date;
    tax: number;
    freight: number;
    total: number;
    pONumber?: string;
    typeId: number;
    statusId: number;
    shipAddressId: number;
    customerId: number;
    shipMethod?: string;
    subtotal: number;
    // shipAddress: CustomerAddress;
    // customer: Customer;
    // invoiceLines: InvoiceLine[];
};

export type WebPhotos = {
    homeSliderPhotos: string[];
};

@Injectable({
    providedIn: 'root'
})
export class StoreService {
    private store = signal<AppStore>({
        loading: signal(false),
        partTypes: signal([]),
        makes: signal([]),
        makeContents: signal([]),
        invoice: signal(new Invoice()),
        mode: signal('shop'),
        webPhotos: signal({ homeSliderPhotos: [] }),
        partTypeModelMaps: signal([]),
        shippingRate: signal(null),
        shippingRateRequest: signal(null),
        shippingEstimates: signal(null),
        shippingEstimatesRequest: signal(null),
        promotions: signal([])
    } as AppStore);

    readonly loading = computed(() => this.store().loading());
    readonly makes = computed(() => this.store().makes!());
    readonly makeContents = computed(() => this.store().makeContents());
    readonly partTypes = computed(() => this.store().partTypes!());
    readonly partTypeModelMaps = computed(() => this.store().partTypeModelMaps!());
    readonly invoice = computed(() => this.store().invoice!());
    readonly mode = computed(() => this.store().mode!());
    readonly webPhotos = computed(() => this.store().webPhotos());
    readonly shippingRate = computed(() => this.store().shippingRate());
    readonly shippingRateRequest = computed(() => this.store().shippingRateRequest());
    readonly shippingEstimates = computed(() => this.store().shippingEstimates());
    readonly shippingEstimatesRequest = computed(() => this.store().shippingEstimatesRequest());
    readonly promotions = computed(() => this.store().promotions());
    readonly invoiceSummary = signal<InvoiceSummary>({} as InvoiceSummary);

    setLoading(value: boolean) {
        this.store.update((s) => ({ ...s, loading: signal(value) }));
    }

    setMode(value: 'shop' | 'payment') {
        this.store.update((s) => ({ ...s, mode: signal(value) }));
    }

    setPartTypes(partTypes: PartType[]) {
        this.store.update((s) => ({ ...s, partTypes: signal(partTypes) }));
    }

    setMakes(mappedMakes: Make[]) {
        this.store.update((s) => ({ ...s, makes: signal(mappedMakes) }));
    }

    setMakeContents(makeContents: MakeContent[]) {
        this.store.update((s) => ({ ...s, makeContents: signal(makeContents) }));
    }

    setInvoice(invoice: Invoice) {
        this.store.update((s) => ({ ...s, invoice: signal(invoice) }));
        this.updateInvoiceSummary();
    }

    updateInvoiceSummary() {
        const invoice = this.invoice();
        this.invoiceSummary.set({
            id: invoice.id,
            invoiceNumber: invoice.invoiceNumber,
            date: invoice.date,
            tax: invoice.tax,
            freight: invoice.freight,
            total: invoice.total,
            pONumber: invoice.pONumber,
            typeId: invoice.typeId,
            statusId: invoice.statusId,
            shipAddressId: invoice.shipAddressId,
            customerId: invoice.customerId,
            shipMethod: invoice.isShipMethodFreight ? 'Freight' : invoice.shipMethod,
            subtotal: invoice.subtotal
        });
    }

    setWebPhotos(webPhotos: WebPhotos) {
        this.store.update((s) => ({ ...s, webPhotos: signal(webPhotos) }));
    }

    setPartTypeModelMaps(maps: PartTypeModelMap[]) {
        this.store.update((s) => ({ ...s, partTypeModelMaps: signal(maps) }));
    }

    setShippingRate(shippingRate: number | null = null) {
        this.store.update((s) => ({ ...s, shippingRate: signal(shippingRate) }));
    }

    setShippingRateRequest(request: ShippingRequest | null = null) {
        this.store.update((s) => ({ ...s, shippingRateRequest: signal(request) }));
    }

    setShippingEstimates(shippingEstimates: ShippingEstimate[] | null = null) {
        this.store.update((s) => ({ ...s, shippingEstimates: signal(shippingEstimates) }));
    }

    setShippingEstimatesRequest(request: ShippingRequest | null = null) {
        this.store.update((s) => ({ ...s, shippingEstimatesRequest: signal(request) }));
    }

    setPromotions(promotions: Promotion[] = []) {
        this.store.update((s) => ({ ...s, promotions: signal(promotions) }));
    }
}
