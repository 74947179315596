import { PartsService } from "../parts.service";
import { Photo } from "./photo";
import { Promotion } from "./promotion";

export class Part {
    id!: number;
    title?: string;
    year?: string;
    make?: string;
    model?: string;
    partNumber?: string;
    tagNumber?: string;
    partTypeId!: number;
    partTypeName?: string;
    condition!: string;
    weight?: number;
    length?: number;
    width?: number;
    height?: number;
    price!: number;
    coreCharge!: number;
    quantity!: number;
    promotionPrice!: number;
    description?: string;
    serialized!: boolean;
    isFeatured!: boolean;
    photoFileName?: string;
    photos!: Photo[];
    promotions!: string[];

    setPromotions(promotions: Promotion[]) {
        this.promotions = promotions.map(p => p.text);
        this.promotionPrice = PartsService.getPromotionPrice(this.price, promotions);
    }

    public constructor(init?:Partial<Part>) {
        Object.assign(this, { ...init,
            title: [(init?.tagNumber ? `${init?.tagNumber} -` : ''), init?.year, init?.make, init?.model, init?.partTypeName, init?.partNumber].join(' '),
            photos: init?.photos?.map((photo) => new Photo(photo)) ?? [],
            promotions: init?.promotions ?? [],
            promotionPrice: init?.promotionPrice ?? init?.price
        });
    }
}
